.body {
  font-family: 'Poppins';

}

.logo {
  width: 30px;
  height: 30px;
}

.logoFont {
  font-family: 'Karla';
  font-weight: 600;
  letter-spacing: 1px;
  margin-left: 3px;
}

.header {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  z-index: 100;
  padding: 1.3rem;

}

.services-heading {
  font-weight: 800;
  font-size: 2.5rem
}


.sub-body {}

.sub-text1 {

  font-size: 1.1rem;
  font-weight: 300;
  padding: 2vw 7vw;
  margin-top: 1.4rem;
  margin-bottom: 3rem;
  line-height: 30px;
}

.services {
  text-align: center;
  background-color: #e7eefb;
  padding: 5rem;


  /* width: 80%;
  max-width: 600px;
  padding: 20px;
  position: relative;
  background: repeating-linear-gradient(-45deg,
      #ffffff,
      #ffffff 10px,
      #ecf0f1 10px,
      #ecf0f1 20px);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}


.about {
  background-color: #161c28;
  color: white;
  padding: 4rem;
}

.contact {
  padding: 4rem;
  z-index: -1;
}

.footer {
  background-color: #161c28;
  color: white;
  text-align: center;
  padding: 5rem;
}



.background {

  position: relative;
  background-image: url('./assets/backgroundtechloom.jpeg');
  /* Adjust the path accordingly */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  /* Ensure the background covers the entire viewport height */
  margin: 0;
  /* Remove default body margin */


}


.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 93vh;
}


.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0, 0.5); */
  background-color: rgba(0, 0, 2, 0.513);
  /* Semi-transparent black overlay */

}

.content {
  position: relative;
  z-index: 1;
  /* Ensure content appears above the overlay */
  padding: 20px;
  color: #fff;
  /* Adjust text color for better visibility */
}

.heading {
  font-weight: 800;
  font-size: 36px;
}

.image1 {
  background: url('./assets/techloom1.jpeg');


}

.noheader {
  display: none;
}

.overlay {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  padding: 4rem;
  border-radius: 30px;
  color: white;
  margin: 1rem 0rem;
}

.image2 {
  background: url('./assets/techloom2.jpeg');

}

.image3 {
  background: url('./assets/techloom3.jpeg');
  background-position: bottom !important;

}

.about-text {
  font-size: 1.2rem;
}

@media screen and (max-width: 360px) {

  .center {
    height: 70vh;
  }

  .heading {
    font-size: 38px
  }

  .services {
    padding: 1rem;
  }

  .services-heading {
    font-weight: 800;
    font-size: 1.8rem;
    margin-top: 2rem;
  }

  .about {
    padding: 1rem;
  }

  .contact {
    padding: 1rem;
    padding-top: 3rem;
  }

  .about-text {
    font-size: 1rem;
    line-height: 30px;
  }

  .techloomImage {
    width: 92vw;
    margin-bottom: 3rem;
  }



}

@media (min-width: 600px) {
  .heading {
    font-size: 65px
  }

  .sub-text {
    font-size: 1.1rem;
    padding: 0rem 10vw;

  }

  .techloomImage {

    width: 85vw;
  }

}

/* Adjust font size for larger screens (desktop) */
@media (min-width: 1024px) {

  .sub-text1 {
    padding-right: 38vw;
    font-size: 1.4rem;
    line-height: 40px;
  }

  .contact-text {
    font-size: 1.2rem;
  }

  .overlay {
    padding: 10rem;
    margin: 3rem 0rem;
  }


  .heading {
    font-size: 90px
  }

  .sub-text {
    font-size: 1.3rem;
    padding: 0rem 30vw;
  }

  .techloomImage {

    width: 46vw;
  }
}